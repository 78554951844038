const state = {
    userOrientations: [],
    orientations: {
        hr: [215352, 215348, 215336, 215341, 215342, 215354],
        it: [215347],
        finance: [218936, 215343, 215337, 215334, 215353, 215324],
        industry: [215335, 215346, 215344, 215349, 215328, 215350, 215355, 215336, 215341, 215331, 215333, 215340, 215338, 215329],
        pr: [215327, 215343, 215323, 215351]
    },
    originLink: 'https://rsv.ru/internships/?isOpenVacancy=true',

    dialogAlexGreetings: {
        title: "Алексей говорит",
        spritesheet: 'dialogDefault',
        npcImage: 'leha',
        bvi_background: '/assets/images/OpenSpace/open_space.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Тебя приветствую, юный падаван! Добро пожаловать в наш орден… то есть в «Компанию мечты Плюс». Меня зовут Алексей, и я буду наставником твоим.',
                buttons: [
                    {text: "Приветствую", setStatuses: [{status:'director', count: 1}], action: {name: 'setScreenAction', arg: 1}},
                    {text: "Давно хотел попасть в орден", setStatuses: [{status:'director', count: 2}], action: {name: 'setScreenAction', arg: 1}},
                    {text: "Это я буду наставником", setStatuses: [{status:'director', count: -2}], action: {name: 'setScreenAction', arg: 1}},
                    {text: "Эх, Лёха, Лёха", setStatuses: [{status:'director', count: -1}], action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Вопрос 2 индекс 1
            {
                dialogText: 'Мы тех ценим, кто работает много и эффективно, а также капибар уважает. Если ты капибара — замечательно, если нет, то есть к чему стремиться.',
                buttons: [
                    {text: "Я — капибара в душе", achievement: 'capibara', setStatuses: [{status:'director', count: 1}], action: {name: 'setScreenAction', arg: 2}},
                    {text: "Буду стремиться", setStatuses: [{status:'director', count: 2}], action: {name: 'setScreenAction', arg: 2}},
                    {text: "Жду первую задачу", setStatuses: [{status:'effectiveness', count: 1}], action: {name: 'setScreenAction', arg: 2}},
                    {text: "Что за чушь?", setStatuses: [{status:'director', count: -1},], action: {name: 'setScreenAction', arg: 2}},
                ]
            },
            //Вопрос 3 индекс 2
            {
                dialogText: 'Во время стажировки познаешь ты источник силы профессиональной и опыт бесценный обретёшь. Но чтобы начать свой путь, надо пройти испытания: сходить на планёрку, познакомиться с коллегами и регламентами нашими, а также выполнить одно особое задание.',
                buttons: [
                    {text: "Да прибудет со мной сила", action: {name: 'setScreenAction', arg: 3}},
                ]
            },
            {
                dialogText: 'У каждого твоего поступка будут последствия, которые влияют на показатели — отношения с коллективом и руководителем, личную эффективность и психологическое состояние.\n\nВ конце игры тебя ждёт сюрприз от проекта «Профразвитие».',
                buttons: [
                    {text: "Всё понятно, поехали!", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: false}}},
                ]
            },
        ]
    },

    dialogAlexFinal: {
        title: "Алексей говорит",
        spritesheet: 'dialogDefault',
        npcImage: 'leha',
        bvi_background: '/assets/images/OpenSpace/open_space.png',
        screens: [
            {
                dialogText: '— Поздравляю с завершением первого рабочего дня! Ты уже продемонстрировал интерес и энтузиазм, но многое ещё узнать тебе предстоит, мой юный падаван. Это только начало.',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            {
                dialogText: 'В знак нашей благодарности за твою усердную работу, я хочу подарить тебе нечто особенное. Подарок будет ждать тебя на твоём рабочем столе.\n' +
                    '\n\n И помни! Многое узнать ты можешь впереди еще, мой юный падаван. Это только начало.\n',
                buttons: [
                    {text: "Забрать подарок", action: {name: 'closeDialog', arg: {goTo: 'WorkPlaceScene', deactivate: false}}},
                ]
            }
        ]
    },

    dialogPlanningUp: {
        spritesheet: 'dialogPlanningUp',
        background: 'planningUp_background',
        animations: 'dialogPlanningUp',
        bvi_background: '/assets/images/PlanningUp/planningUp_background.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Планёрка запланирована через 15 минут, но тебе уже прислали ссылку на онлайн встречу в «Волне». Наставник объяснил, что это внутренний сервис видеосвязи, который разработали, чтобы не допустить утечки конфиденциальной информации. Про правила использования ничего не рассказали. Как поступишь?',
                buttons: [
                    {text: 'Разберусь самостоятельно', setStatuses: [{status:'effectiveness', count: 2}],   action: {name: 'setScreenAction', arg: 1}},
                    {text: 'Спрошу у коллеги', setStatuses: [{status:'comrades', count: 2}],  action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Расслаблюсь', setStatuses: [{status:'psychological', count: 2}], action: {name: 'setScreenAction', arg: 3}},
                ]
            },
            //Ответ 1 на Вопрос 1 индекс 1
            {
                dialogText: 'Наставник упомянул, что у них есть база знаний, в которой хранится много всего полезного. Там точно должна быть инструкция к «Волне». Ты находишь эту базу и проваливаешься в статьи, ссылки, гайды. Чтение настолько увлекает, что ты немного опаздываешь на планёрку. Кажется, что никто не заметил.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Ответ 2 на Вопрос 1 индекс 2
            {
                dialogText: 'Девушку, которая сидит за соседним столом, зовут Аня. Она легко откликается на твою просьбу и за пять минут показывает все важные функции «Волны» на своём компьютере. Кажется, что подготовка к созвону прошла успешно, а у тебя появилась новая знакомая в компании.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Ответ 3 на Вопрос 1 индекс 3
            {
                dialogText: 'Все сервисы для видеосвязи — выглядят примерно одинаково. У тебя есть 100% уверенность, что ты сможешь со всем разобраться на ходу. А в свободное время лучше посмотреть смешные видео: они помогут расслабиться и избавят от мандража в ожидании созвона.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Вопрос 2 индекс 4
            {
                dialogText: 'Планёрка идёт своим чередом. Твой наставник рассказывает команде о выполненных задачах и ставит цели на эту неделю. Вдруг ты обращаешь внимание на окошко со своим изображением: кто-то до тебя поставил на фон дерущихся мужчин в костюмах пиццы, а вместо имени — никнейм 123omnomnom. Какой ужас! Что будешь делать?',
                buttons: [
                    {text: 'Быстро отредактирую', setStatuses: [{status:'effectiveness', count: 1},{status:'psychological', count: 1},], action: {name: 'setScreenAction', arg: 5}},
                    {text: 'Классно пошучу', setStatuses: [{status:'comrades', count: 1},{status:'director', count: -1},], action: {name: 'setScreenAction', arg: 6}},
                    {text: 'Нажму на красную кнопку', setStatuses: [{status:'director', count: -1},{status:'psychological', count: -1},], action: {name: 'setScreenAction', arg: 7}},
                ]
            },
            //Ответ 1 на Вопрос 2 индекс 5
            {
                dialogText: 'Ты быстро меняешь никнейм на своё имя и указываешь должность, но из-за стресса никак не можешь найти функцию смены фона. Проходит минута, две, и ты наконец находишь нужную кнопку. Интересно, заметил ли кто-то?',
                buttons: [{text: 'Далее',  action: {name: 'setScreenAction', arg: 8}},]
            },
            //Ответ 2 на Вопрос 2 индекс 6
            {
                dialogText: 'Ты включаешь микрофон и прерываешь наставника: «Коллеги, я сегодня веду созвон со спортивного мероприятия. Кто-нибудь подскажет, где выход?»\n',
                buttons: [{text: 'Далее',  action: {name: 'setScreenAction', arg: 8}},]
            },
            //Ответ 3 на Вопрос 2 индекс 7
            {
                dialogText: 'Кто придумал добавить в интерфейс такую притягательную красную кнопку, которая включает гимн компании во время созвона? Корпоративная песня внесла хаос в созвон: сотрудники стали подпевать, а тайминги планёрки растянулись. Наставник ищет виноватого, пришлось сознаться.',
                buttons: [{text: 'Далее',  action: {name: 'setScreenAction', arg: 8}},]
            },
            //Вопрос 3 индекс 8
            {
                dialogText: 'Последний сотрудник завершает свой доклад, и тут наставник представляет тебя команде и передаёт слово. Про что расскажешь?',
                buttons: [
                    {text: 'Про стажировку мечты', setStatuses: [{status:'effectiveness', count: -1},{status:'comrades', count: 1},],  action: {name: 'setScreenAction', arg: 9}},
                    {text: 'Про учёбу', setStatuses: [{status:'effectiveness', count: 1},{status:'comrades', count: 1},],  action: {name: 'setScreenAction', arg: 10}},
                    {text: 'Интересное про себя', setStatuses: [{status:'director', count: -1},{status:'psychological', count: 1},],  action: {name: 'setScreenAction', arg: 11}},
                    {text: 'Про свои задачи', achievement: 'headphones', setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: 1},],  action: {name: 'setScreenAction', arg: 12}},
                ]
            },
            //Ответ 1 на Вопрос 3 индекс 9
            {
                dialogText: 'Все пять минут своего времени ты тратишь на хвалебную речь компании. Коллегам льстят твои слова, но, к сожалению, они не раскрывают тебя как профессионала.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //Ответ 2 на Вопрос 3 индекс 10
            {
                dialogText: 'Ты рассказываешь, чему тебя научили в университете. Делаешь акцент на том, что хорошо знаешь теорию, но никогда не применял её на практике. Коллеги с пониманием относятся к твоим словам, ведь сами когда-то были стажёрами без опыта работы.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //Ответ 3 на Вопрос 3 индекс 11
            {
                dialogText: 'Ты рассказываешь всё самое интересное про себя: про недавнее расставание, про свои навыки таролога, про умнейшую кошку Обжорку, про любимую песню «Ранеток» и про классные сериалы на ТВ-3. Про себя рассказывать приятно, но наставник испытывает стыд — не такого стажёра он брал в компанию.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //Ответ 4 на Вопрос 3 индекс 12
            {
                dialogText: 'Ты стараешься почти дословно пересказать слова наставника о своих рабочих обязанностях. Коллеги слушают и задают вопросы, ты на них отвечаешь. А наставнику приятно, что ты запомнил его учение.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //Финал индекс 13
            {
                dialogText: 'Планёрка подошла к концу. Ты научился пользоваться «Волной» и рассказал о себе коллективу. Можно браться за новые задачи.',
                buttons: [
                    {text: "Перейти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            }
        ]
    },

    dialogPrinter: {
        spritesheet: 'dialogPrinter',
        background: 'printer_background',
        animations: 'dialogPrinter',
        bvi_background: '/assets/images/Printer/printer_background.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Наставник попросил тебя срочно распечатать текст своего доклада. Ты подходишь к принтеру и понимаешь, что бумаги нет. Рядом лежит нераспечатанная упаковка листов A4. Можно ли её открыть? Или лучше взять черновики — не зря же они лежат на столе рядом с принтером.',
                buttons: [
                    {text: 'Открыть пачку', setStatuses: [{status:'effectiveness', count: 1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 1}},
                    {text: 'Спросить разрешения у наставника', setStatuses: [{status:'director', count: 1},{status:'effectiveness', count: -1},], action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Использовать черновик', setStatuses: [{status:'director', count: 1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 3}},
                    {text: 'Нажать на волшебную кнопку', achievement: "areiopagos", setStatuses: [{status:'effectiveness', count: -1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Ответ 1 на Вопрос 1 индекс 1
            {
                dialogText: 'Ты решительно открываешь упаковку листов А4, распечатываешь текст выступления для наставника и стараешься не замечать гневного взгляда бухгалтера Насти. Ведь ты открыл пачку бумаги, которая предназначена исключительно для бухгалтерии. Но кто знал про это?',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Ответ 2 на Вопрос 1 индекс 2
            {
                dialogText: 'Наставник достал для тебя листы из своего личного ящика и напомнил, что настоящий джедай использует Силу для знания и защиты, а не для атаки. Интересно, как это поможет с использованием принтера?',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Ответ 3 на Вопрос 1 индекс 3
            {
                dialogText: 'Ты ловко хватаешь черновики, засовываешь их в принтер и уже через несколько секунд получаешь тезисы для своего наставника. Магистр Алексей доволен, а бухгалтер Настя — нет. Это были не черновики, а её рабочие документы.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Ответ 4 на Вопрос 1 индекс 4
            {
                dialogText: 'Красивая кнопка на принтере так и манит. Интересно, что будет если её нажать? Может волшебным образом появится офис-менеджер и принесёт бумагу? Твой палец касается кнопки, принтер выключается. Всем своим видом он говорит, что магия не сработала. А из офиса раздаётся крик: «Кто выключил принтер?»',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Финал индекс 5
            {
                dialogText: 'Тебе всё-таки удалось выполнить особое поручение наставника. Теперь пора заняться остальными задачами.',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
        ]
    },

    dialogConditioner: {
        spritesheet: 'dialogConditioner',
        background: 'conditioner_background',
        animations: 'dialogConditioner',
        bvi_background: '/assets/images/Conditioner/conditioner_background.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'В офисе душно: ты физически ощущаешь тяжесть воздуха, а одежда начинает постепенно прилипать к телу. Кондиционер не включён, окна закрыты. Кажется, что только ты замечаешь дискомфорт — остальные коллеги работают как ни в чём не бывало. Твои действия?',
                buttons: [
                    {text: 'Включу кондиционер',  setStatuses: [{status:'psychological', count: 1}, {status:'comrades', count: -1},],  action: {name: 'setScreenAction', arg: 1}},
                    {text: 'Громко спрошу разрешения', achievement: 'conditioner',  setStatuses: [{status:'psychological', count: -1},{status:'comrades', count: 1},],  action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Продолжу работу',  setStatuses: [{status:'psychological', count: -2},],  action: {name: 'setScreenAction', arg: 3}},
                    {text: 'Прогуляюсь возле офиса',  setStatuses: [{status:'psychological', count: 1},{status:'director', count: -1},],  action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Ответ 1 на Вопрос 1 индекс 1
            {
                dialogText: 'Пульт от кондиционера лежит рядом. Ты смотришь на него, он смотрит на тебя — искра, буря, безумие. Вот оно спасение! Ты хватаешь пульт и включаешь кондиционер на полную. Тебе хорошо и прохладно, а вот дизайнер Марина смотрит в твою сторону с неодобрением. Надо было спросить разрешения...\n',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
            //Ответ 2 на Вопрос 1 индекс 2
            {
                dialogText: 'Ты громко спрашиваешь, можно ли включить кондиционер. Вроде бы никто не против. Берёшь пульт, нажимаешь на кнопку, дует приятный ветерок. Хорошо!\n',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
            //Ответ 3 на Вопрос 1 индекс 3
            {
                dialogText: '«Настоящий профессионал обязан стойко и мужественно переносить все тяготы и лишения офисной службы», — если ты ничего не путаешь, то примерно такие слова были в уставе компании. В любом случае ты сосредотачиваешься на выполнении рабочих задач, комфорт — для слабаков.',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
            //Ответ 4 на Вопрос 1 индекс 4
            {
                dialogText: 'Чтобы не отвлекать коллег своей просьбой, ты решаешь выйти из офиса и немного подышать свежим воздухом. Тебе хорошо, а вот наставник расстроился, что ты не предупредил.',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            }
        ]
    },

    dialogPapers: {
        spritesheet: 'dialogPapers',
        background: 'papers_background',
        animations: 'dialogPapers',
        bvi_background: '/assets/images/Papers/papers_background.png',
        screensBVI: [
            // Вопрос 1 индекс 0
            {
                dialogText: 'Первый день стажёра не обходится без изучения основных регламентов, инструкций и положений, принятых в организации. Часть документов удалось изучить в отделе кадров, остальное — на сладкое. Алексей напомнил, что важно изучить правила внутреннего распорядка. После он проведёт небольшую проверку по основным пунктам.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            // Вопрос 1 Экран 2 индекс 1
            {
                dialogText: 'В правилах сказано, что в организации действует пропускная система. Ты забываешь или теряешь пропуск. Как поступишь?',
                buttons: [
                    {text: 'Обратиться к коллегам', setStatuses: [{status:'effectiveness', count: 1},{status:'comrades', count: -1},],  action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Отвлечь наставника', achievement: 'keyCard',setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: 1},],  action: {name: 'setScreenAction', arg: 3}},
                    {text: 'Договориться с охранником', setStatuses: [{status:'director', count: -1},{status:'effectiveness', count: -1},],  action: {name: 'setScreenAction', arg: 4}},
                    {text: 'Подождать в коридоре', setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: -1},],  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Ответ 1 на вопрос 1 индекс 2
            {
                dialogText: 'Ты пишешь знакомой коллеге Маше в личные сообщения, в ответ — грозный смайлик. Она сейчас на созвоне. Через пять минут Маша приходит, проводит тебя через КПП и напоминает, что надо обращать внимание на статус в мессенджере. Пусть так, зато ты добираешься до своего рабочего места',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
                ]
            },
            //Ответ 2 на вопрос 1 индекс 3
            {
                dialogText: 'Тебе неловко писать наставнику с такой глупой просьбой, но ты всё же решаешься на отправку сообщения. Алексей проводит тебя через КПП без лишних вопросов и объясняет, как можно восстановить пропуск. А что? Ты же его стажёр — наставник за тебя отвечает.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
                ]
            },
            //Ответ 3 на вопрос 1 индекс 4
            {
                dialogText: 'Договориться с охранником не вышло. Он плохо тебя знает, да и настроение у него не очень — вчера проиграла его любимая команда. Ты устраиваешь скандал, приходит наставник и пропускает тебя. Алексею стыдно, а ты теряешь рабочий настрой.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
                ]
            },
            //Ответ 4 на вопрос 1 индекс 5
            {
                dialogText: 'Ты начинаешь работать в коридоре и устраиваешь засаду на коллег. Твоя задача —  проскочить через турникет вместе с любым сотрудником офиса. Вот идёт потенциальная жертва, охранник отвлёкся, ты проскальзываешь мимо.\n' +
                    'Ура, ты на рабочем месте! А вот твоему наставнику сделали выговор.\n',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
                ]
            },
            //Вопрос 2 индекс 6
            {
                dialogText: 'Друг прислал смешное видео: там такой хохотач, что у тебя не получилось удержаться — и ты смеёшься в голос. И на этом моменте ты вспоминаешь, что шуметь в рабочем пространстве нельзя. Коллеги явно недовольны. Как разрядишь обстановку?',
                buttons: [
                    {text: 'Зайду в рабочий чат', setStatuses: [{status:'comrades', count: 2},], action: {name: 'setScreenAction', arg: 7}},
                    {text: 'Найду другое видео', setStatuses: [{status:'comrades', count: -2},], action: {name: 'setScreenAction', arg: 8}},
                    {text: 'Выйду на кухню', setStatuses: [{status:'psychological', count: 1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 9}},
                    {text: 'Докажу, что видео смешное', achievement: 'memolog', setStatuses: [{status:'comrades', count: 1},], action: {name: 'setScreenAction', arg: 10}},
                ]
            },
            //Ответ 1 на вопрос 2 индекс 7
            {
                dialogText: 'Ты заходишь в рабочий чат и извиняешься перед коллегами. Твоё сообщение собрало много положительных реакций. Кажется, что обстановку удалось разрядить!',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
                ]
            },
            //Ответ 2 на вопрос 2 индекс 8
            {
                dialogText: 'Вместо извинений ты продолжаешь искать смешные видео, но смеёшься уже про себя. Коллег разочаровало твоё равнодушие.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
                ]
            },
            //Ответ 3 на вопрос 2 индекс 9
            {
                dialogText: 'Ты в растерянности уходишь туда, где тебя не будет беспокоить общее осуждение. Тебе удалось расслабиться, а вот коллективу такой жест не понравился.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
                ]
            },
            //Ответ 3 на вопрос 2 индекс 10
            {
                dialogText: 'Ты прислал видео в рабочий чат — оно действительно смешное. Коллеги согласны, что удержаться было невозможно, поэтому прощают тебя. Но лучше в следующий раз быть сдержаннее. ',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
                ]
            },
            //Финал индекс 11
            {
                dialogText: 'Ты успешно справляешься с мини-экзаменом от наставника. Алексей доволен.',
                buttons: [
                    {text: "Пойти в опенспейс",achievement: 'capibara', action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
        ],
        screens: [
          // Вопрос 1 индекс 0
          {
                dialogText: 'Первый день стажёра не обходится без изучения основных регламентов, инструкций и положений, принятых в организации. Часть документов удалось изучить в отделе кадров, остальное — на сладкое. Алексей напомнил, что важно изучить правила внутреннего распорядка. После он проведёт небольшую проверку по основным пунктам.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 1}},
                ]
          },
          // Вопрос 1 Экран 2 индекс 1
          {
              dialogText: 'В правилах сказано, что в организации действует пропускная система. Ты забываешь или теряешь пропуск. Как поступишь?',
              buttons: [
                  {text: 'Обратиться к коллегам', setStatuses: [{status:'effectiveness', count: 1},{status:'comrades', count: -1},],  action: {name: 'setScreenAction', arg: 2}},
                  {text: 'Отвлечь наставника', achievement: 'keyCard',setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: 1},],  action: {name: 'setScreenAction', arg: 3}},
                  {text: 'Договориться с охранником', setStatuses: [{status:'director', count: -1},{status:'effectiveness', count: -1},],  action: {name: 'setScreenAction', arg: 4}},
                  {text: 'Подождать в коридоре', setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: -1},],  action: {name: 'setScreenAction', arg: 5}},
              ]
          },
          //Ответ 1 на вопрос 1 индекс 2
          {
              dialogText: 'Ты пишешь знакомой коллеге Маше в личные сообщения, в ответ — грозный смайлик. Она сейчас на созвоне. Через пять минут Маша приходит, проводит тебя через КПП и напоминает, что надо обращать внимание на статус в мессенджере. Пусть так, зато ты добираешься до своего рабочего места',
              buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
              ]
          },
          //Ответ 2 на вопрос 1 индекс 3
          {
              dialogText: 'Тебе неловко писать наставнику с такой глупой просьбой, но ты всё же решаешься на отправку сообщения. Алексей проводит тебя через КПП без лишних вопросов и объясняет, как можно восстановить пропуск. А что? Ты же его стажёр — наставник за тебя отвечает.',
              buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
              ]
          },
          //Ответ 3 на вопрос 1 индекс 4
          {
              dialogText: 'Договориться с охранником не вышло. Он плохо тебя знает, да и настроение у него не очень — вчера проиграла его любимая команда. Ты устраиваешь скандал, приходит наставник и пропускает тебя. Алексею стыдно, а ты теряешь рабочий настрой.',
              buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
              ]
          },
          //Ответ 4 на вопрос 1 индекс 5
          {
              dialogText: 'Ты начинаешь работать в коридоре и устраиваешь засаду на коллег. Твоя задача —  проскочить через турникет за любым сотрудником, идущим в офис. Вот идёт потенциальная жертва, охранник отвлёкся, ты проскальзываешь мимо.\n' +
                  '\n\nУра, ты на рабочем месте! А вот твоему наставнику сделали выговор.\n',
              buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
              ]
          },
          //Вопрос 2 индекс 6
          {
              dialogText: 'Друг прислал смешное видео: там такой хохотач, что у тебя не получилось удержаться — и ты смеёшься в голос. И на этом моменте ты вспоминаешь, что шуметь в рабочем пространстве нельзя. Коллеги явно недовольны. Как разрядишь обстановку?',
              buttons: [
                  {text: 'Зайду в рабочий чат', setStatuses: [{status:'comrades', count: 2},], action: {name: 'setScreenAction', arg: 7}},
                  {text: 'Найду другое видео', setStatuses: [{status:'comrades', count: -2},], action: {name: 'setScreenAction', arg: 8}},
                  {text: 'Выйду на кухню', setStatuses: [{status:'psychological', count: 1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 9}},
                  {text: 'Докажу, что видео смешное', achievement: 'memolog', setStatuses: [{status:'comrades', count: 1},], action: {name: 'setScreenAction', arg: 10}},
              ]
          },
          //Ответ 1 на вопрос 2 индекс 7
          {
              dialogText: 'Ты заходишь в рабочий чат и извиняешься перед коллегами. Твоё сообщение собрало много положительных реакций. Кажется, что обстановку удалось разрядить!',
              buttons: [
                {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
              ]
          },
          //Ответ 2 на вопрос 2 индекс 8
          {
              dialogText: 'Вместо извинений ты продолжаешь искать смешные видео, но смеёшься уже про себя. Коллег разочаровало твоё равнодушие.',
              buttons: [
                {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
              ]
          },
          //Ответ 3 на вопрос 2 индекс 9
          {
              dialogText: 'Ты в растерянности уходишь туда, где тебя не будет беспокоить общее осуждение. Тебе удалось расслабиться, а вот коллективу такой жест не понравился.',
              buttons: [
                {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
              ]
          },
          //Ответ 3 на вопрос 2 индекс 10
          {
              dialogText: 'Ты прислал видео в рабочий чат — оно действительно смешное. Коллеги согласны, что удержаться было невозможно, поэтому прощают тебя. Но лучше в следующий раз быть сдержаннее. ',
              buttons: [
                {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
              ]
          },
          //Финал индекс 11
            {
                dialogText: 'Ты забываешь сроки согласования служебной записки и теперь не можешь получить доступ к корпоративной сети. Придётся пробежаться по офису и быстро собрать недостающие подписи.',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 12}},
                ]
            },
            //Финал индекс 12
          {
              dialogText: 'Для перемещения используй клавиатуру, если играешь с компьютера, или тачпад, если играешь со смартфона. Постарайся собрать как можно больше недостающих подписей.',
              buttons: [
                  {text: "Играть", action: {name: 'closeDialog', arg: {goTo: 'ArcadeGameScene', deactivate: true}}},
              ]
          },
        ]
    },

    dialogPapersFinish: {
        spritesheet: 'dialogPapers',
        background: 'papers_background',
        animations: 'dialogPapers',
        bvi_background: '/assets/images/Papers/papers_background.png',
        screens: [
            // Вопрос 1 индекс 0
            {
                dialogText: 'Ты успешно справляешься с мини-экзаменом от наставника. Алексей доволен.',
                buttons: [
                    {text: "Играть ещё", action: {name: 'closeDialog', arg: {goTo: 'ArcadeGameScene', deactivate: false}}},
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: false}}}
                ]
            },
        ]
    },

    dialogComrades: {
        spritesheet: 'dialogComrades',
        background: 'comrades_background',
        animations: 'dialogComrades',
        bvi_background: '/assets/images/Comrades/comrades_background.png',
        screensBVI: [
            {
                dialogText: 'Наставник подзывает тебя к себе, чтобы познакомить с коллегами. «Знакомьтесь ребята, это наш стажёр, — говорит Алексей, — а это Маша — занимается дизайном, Вера — продумывает воронку продаж, Григорий — руководит пулом внештатных копирайтеров, Светлана — бухгалтер, а ещё есть Настя, Кристина, Витя и Коля. Надеюсь, что всех запомнил. Так ведь?»\n',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            {
                dialogText: 'Классно, у тебя получилось! Главное — не забыть через пять минут всё, что удалось запомнить.',
                buttons: [
                    {text: "Вернуться в опенспейс",achievement: 'comrades', action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
        ],
        screens: [
            {
                dialogText: 'Наставник подзывает тебя к себе, чтобы познакомить с коллегами. «Знакомьтесь ребята, это наш стажёр, — говорит Алексей, — А это Маша — она занимается дизайном, Вера — продумывает воронку продаж, Григорий — руководит пулом внештатных копирайтеров, Светлана — бухгалтер, а ещё есть Настя, Кристина, Витя и Коля. Надеюсь, что всех запомнил. Так ведь?»\n',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            {
                dialogText: 'Сейчас тебе нужно будет сыграть в «Мемори» с коллегами. Переворачивай карточки, запоминай коллегу и находи похожую карточку. Игра закончится, когда найдёшь все пары. Постарайся это сделать как можно быстрее.\n',
                buttons: [
                    {text: "Играть", achievement: 'comrades', action: {name: 'closeDialog', arg: {goTo: 'MemoryGameScene', deactivate: true}}},
                ]
            }
        ]
    },

    dialogComradesFinish: {
        spritesheet: 'dialogComrades',
        background: 'comrades_background',
        animations: 'dialogComrades',
        bvi_background: '/assets/images/Comrades/comrades_background.png',
        screens: [
            // Вопрос 1 индекс 0
            {
                dialogText: 'Классно, у тебя получилось! Главное — не забыть через пять минут всё, что удалось запомнить.',
                buttons: [
                    {text: "Играть ещё", action: {name: 'closeDialog', arg: {goTo: 'MemoryGameScene', deactivate: false}}},
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: false}}}
                ]
            },
        ]
    },

    //Переход на кухню
    dialogToKitchen: {
        title: "Новогоднее настроение",
        spritesheet: 'dialogDefault',
        bvi_background: '/assets/images/OpenSpace/open_space.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Второй день стажировки удивляет с первой минуты. В офисе движуха — приближается новогодний корпоратив. На длинных столах лежат блестящие упаковки с подарками, мандарины, хлопушки и сверкающая мишура. Сотрудники суетятся, обсуждая детали: кто что принесёт, кто станет ведущим, как украсить офис, чтобы он выглядел по-настоящему празднично.',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Вопрос 2 индекс 1
            {
                dialogText: 'В углу оупен-спейса дизайнеры спорят, как оформить новогодние открытки. Кто-то предложил нарисовать гигантскую капибару высотой с Останкинскую башню, в руках у которой гитара, а наверху подпись: «Как здорово, что все мы здесь сегодня собрались!». Пока одни коллеги смеются, другие предлагают свои идеи.',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 2}},
                ]
            },
            //Вопрос 3 индекс 2
            {
                dialogText: 'Из кухни слышно шипение кофемашины. Кажется, опять забыли налить воды. Появлятся Алексей, который явно кого-то ищет',
                buttons: [
                    {text: "Далее", action: {name: 'changeDialog', arg: {dialog: 'dialogAlexToKitchen', deactivate: false}}},
                ]
            }
        ]
    },

    dialogAlexToKitchen: {
        title: "Алексей говорит",
        spritesheet: 'dialogDefault',
        npcImage: 'leha',
        bvi_background: '/assets/images/OpenSpace/open_space.png',
        screens: [
            {
                dialogText: 'О, стажёр! Тебя-то я и искал. Можешь заглянуть на кухню? Там нужна помощь. Поднимайся на второй этаж, там подскажут, что делать.',
                buttons: [
                    {text: "Ммм, куухня", action: {name: 'changeDialog', arg: {dialog: 'dialogHelloToKitchen', deactivate: false}}},
                ]
            }
        ]
    },

    dialogHelloToKitchen: {
        title: "Кухня в офисе",
        background: "kitchen",
        spritesheet: 'dialogKitchenDefault',
        bvi_background: '/assets/images/Kitchen/kitchen.png',
        screens: [
            {
                dialogText: 'На стенах свисают яркие бумажные гирлянды, но столы еще не накрыты — они ждут угощений. В воздухе аромат свежеиспеченного печенья, расположенного на небольшом столике рядом с мягким диваном. Кто-то подходит к тебе со спины и кладёт свою руку на твоё плечо.',
                buttons: [
                    {text: "Далее", action: {name: 'changeDialog', arg: {dialog: 'dialogSvetaKitchen', deactivate: false}}},
                ]
            },
        ]
    },

    dialogSvetaKitchen: {
        title: "Светлана говорит",
        background: "kitchen",
        spritesheet: 'dialogKitchenDefault',
        npcImage: 'sveta',
        bvi_background: '/assets/images/Kitchen/kitchen.png',
        screens: [
            {
                dialogText: 'О, привет! Вот как выглядит наш новый стажёр. Меня зовут Света — я офис-менеджер. Твой наставник Алексей сказал, что ты можешь помочь.',
                buttons: [
                    {text: "С удовольствием!", action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            {
                dialogText: 'Надо починить кофемашину, разобрать холодильник, написать поздравительное письмо клиентам — в общем много мелочей, которые ждут своего героя. Справишься? Тогда за дело.',
                buttons: [
                    {text: "Можешь на меня положиться!", action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: false}}},
                ]
            },
        ]
    },

    dialogNotebook: {
        title: "Ноутбук",
        background: "kitchen",
        spritesheet: 'dialogKitchenDefault',
        bvi_background: '/assets/images/Kitchen/kitchen.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Лёгкое волнение, смешанное с азартом, — первая по-настоящему ответственная задача сегодня. Твой взгляд зависает на экране монитора. Ты размышляешь о том, как лучше начать поздравительное письмо.',
                buttons: [
                    {text: 'Открыть шаблон письма', setStatuses: [{status:'effectiveness', count: -1}],   action: {name: 'setScreenAction', arg: 1}},
                    {text: 'Поискать референсы', setStatuses: [{status:'effectiveness', count: 1}, {status:'psychological', count: -1}],  action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Обратиться к коллегам', setStatuses: [{status:'comrades', count: 1}, {status:'effectiveness', count: 1}], action: {name: 'setScreenAction', arg: 3}},
                ]
            },
            //0твет 1.1 индекс 1
            {
                dialogText: "Открыв шаблон письма, ты чувствуешь облегчение. Структура уже готова, нужно только заполнить пустые поля. Хотя начало письма звучит формально, ты знаешь, что оно соответствует корпоративным стандартам и будет понятно партнёрам.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //0твет 1.2 индекс 2
            {
                dialogText: "Решив поискать вдохновение, ты открываешь браузер и ищешь примеры поздравительных сообщений. Собирая идеи, ты начинаешь формировать в голове собственное видение того, как должно выглядеть письмо.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //0твет 1.3 индекс 3
            {
                dialogText: "Ты отвлекаешься от экрана и прислушиваешься к разговору коллег — они обсуждают корпоратив. Ты слышишь забавные истории, которые вдохновляют тебя на нестандартные формулировки. Кажется, у тебя родился креативный концепт для начала письма.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Вопрос 2 индекс 4
            {
                dialogText: 'Собравшись с мыслями, ты садишься за поздравление. Первые строки идут легко: ты пишешь искренне и уже представляешь, как клиенты с улыбкой будут читать это письмо. Однако когда доходит до кульминации, ты чувствуешь небольшую заминку. Как же выразить пожелания так, чтобы они звучали по-настоящему особенными?',
                buttons: [
                    {text: 'Добавить личные пожелания', setStatuses: [{status:'effectiveness', count: 1}, {status:'psychological', count: -1}],   action: {name: 'setScreenAction', arg: 5}},
                    {text: 'Проверить корпоративный стиль', setStatuses: [{status:'psychological', count: 1}],  action: {name: 'setScreenAction', arg: 6}},
                    {text: 'Сделать перерыв', setStatuses: [{status:'psychological', count: 2}], action: {name: 'setScreenAction', arg: 7}},
                ]
            },
            //0твет 2.1 индекс 5
            {
                dialogText: "Ты добавляешь новую строчку: «Надеемся, что в Новом году у вас будет больше поводов для праздников и меньше затянувшихся встреч!» Ты улыбаешься — письмо стало более живым, хотя и пришлось немного отойти от официально-делового стиля",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 8}},
                ]
            },
            //0твет 2.2 индекс 6
            {
                dialogText: "Сравнивая свои строки с примерами из корпоративного стиля, ты понимаешь, что нужно сделать текст более сдержанным и профессиональным. Немного поработав с текстом, ты чувствуешь, как уверенность возвращается — ты на верном пути!",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 8}},
                ]
            },
            //0твет 2.3 индекс 7
            {
                dialogText: "Встав из-за стола, ты отправляешься к окну, чтобы немного отвлечься. Ты смотришь, как украшают улицу к празднику — красиво! Вернувшись к ноутбуку, чувствуешь, что мысли стали яснее. Теперь можно продолжить работу.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 8}},
                ]
            },
            //Вопрос 3 индекс 8
            {
                dialogText: 'Собравшись с силами, ты добавляешь финальные штрихи и завершаешь письмо. Прочитав его несколько раз, ты понимаешь, что оно идеально. Курсор замирает на кнопке «Отправить», волнительно. Это первое официальное письмо от тебя — ты задумываешься, что делать дальше.',
                buttons: [
                    {text: 'Отправить письмо', setStatuses: [{status:'psychological', count: 1}, {status:'comrades', count: -1}],   action: {name: 'setScreenAction', arg: 9}},
                    {text: 'Сделать паузу', setStatuses: [{status:'psychological', count: 1}, {status:'comrades', count: 1}],  action: {name: 'setScreenAction', arg: 10}},
                    {text: 'Обсудить с менеджером', setStatuses: [{status:'comrades', count: 1}], action: {name: 'setScreenAction', arg: 11}},
                ]
            },
            //0твет 3.1 индекс 5
            {
                dialogText: "Нажав кнопку «Отправить», ты чувствуешь, как тяжёлый груз падает с плеч. Волнение сменяется облегчением, и на душе становится тепло от мысли, что твои слова сделали чей-то день лучше.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 12}},
                ]
            },
            //0твет 3.2 индекс 6
            {
                dialogText: "Сначала ты чувствуешь лёгкое напряжение, но потом оно сменяется уверенностью в том, что это был правильный выбор. Ты сохраняешь черновик и решаешь немного отдохнуть, чтобы после вместе с коллегами обсудить написанное. Дело сделано!",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 12}},
                ]
            },
            //0твет 3.3 индекс 7
            {
                dialogText: "Ты зовёшь офис-менеджера к ноутбуку и замираешь, ожидая реакцию. Света внимательно прочитала текст и одобрительно кивнула. После небольшой обратной связи и нескольких полезных предложений, ты вносишь правки и заканчиваешь работу.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 12}},
                ]
            },
            //Финал индекс 12
            {
                dialogText: 'Отличная работа! Внимательность к деталям и умение прислушиваться к потребностям клиентов — это основа компетенции «клиентоориентированность». Если хочешь оценить свои навыки прямо сейчас, то можешь пройти тест.',
                buttons: [
                    {text: "Продолжить", action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: true}}},
                    {text: "Пройти тест", action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: true, link: 'https://testing.rsv.ru/82bc646c37d34038b2cae3d11f228d18'}}},
                ]
            },
        ]
    },

    dialogCoffee: {
        title: "Кофемашина",
        background: "kitchen",
        spritesheet: 'dialogKitchenDefault',
        bvi_background: '/assets/images/Kitchen/kitchen.png',
        screensBVI: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Ты совсем ничего не знаешь про починку таких устройств, но подводить коллег совсем не хочется. Что-нибудь придумаем. Ты подходишь к аппарату, внимательно осматриваешь его и стараешься вспомнить всё, что знаешь про кофемашины. Кажется, чтобы всё наладилось, надо сыграть в мини-игру. Точно, так всё и работает в реальной жизни!',
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Финиш индекс 1
            {
                dialogText: 'Ты успешно справляешься с мини-экзаменом от наставника. Алексей доволен.',
                buttons: [
                    {text: "Вернуться на кухню", action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: true}}},
                ]
            }
        ],
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Ты совсем ничего не знаешь про починку таких устройств, но подводить коллег совсем не хочется. Что-нибудь придумаем. Ты подходишь к аппарату, внимательно осматриваешь его и стараешься вспомнить всё, что знаешь про кофемашины. Кажется, чтобы всё починилось, надо сыграть в мини-игру. Точно, так всё и работает в реальной жизни!',
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Вопрос 2 индекс 1
            {
                dialogText: 'Твоя задача - починить кофемашину. Следи за стрелкой, найди зелёную зону и кликни или тапни, когда стрелка окажется в ней. Каждая удачная остановка завершает этап ремонта. Удачи!',
                buttons: [
                    {text: 'Далее', action: {name: 'closeDialog', arg: {goTo: 'CoffeeGameScene', deactivate: true}}},
                ]
            },
        ]
    },

    dialogCoffeeFinish: {
        title: "Кофемашина",
        background: "kitchen",
        spritesheet: 'dialogKitchenDefault',
        bvi_background: '/assets/images/Kitchen/kitchen.png',
        screens: [
            // Финал индекс 0
            {
                dialogText: 'Если со временем разонравится работа в офисе, ты всегда можешь заняться ремонтом кофемашин. Отличная работа!',
                buttons: [
                    {text: "Вернуться на кухню", action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: false}}},
                    {text: 'Играть ещё раз', action: {name: 'closeDialog', arg: {goTo: 'CoffeeGameScene', deactivate: false}}},
                ]
            },
        ]
    },

    dialogRefrigerator: {
        title: "Холодильник",
        background: "kitchen",
        spritesheet: 'dialogKitchenDefault',
        bvi_background: '/assets/images/Kitchen/kitchen.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Открытая дверца холодильника встречает тебя резким запахом — будто у тебя получилось открыть портал в мир потерянной еды. Контейнеры с непонятным содержимым прочно приросли к нижней полке. Некоторые из них покрыты плесенью, а на других угрожающе поднимается конденсат.',
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Вопрос 2 индекс 1
            {
                dialogText: 'Ты с недоумением разглядываешь наклейки, на которых давно стерлись даты. Через минуту приходит понимание, что некоторые из них “живут” еще со времен предыдущего  новогоднего корпоратива.',
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 2}},
                ]
            },
            //Вопрос 2 индекс 2
            {
                dialogText: 'Просто выбросить в помойку этих бессловесных свидетелей офисной жизни — было бы настоящим преступлением. Ты решаешь всё отсортировать и помочь контейнерам со свежей едой “спастись” из этого холодильного царства',
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 3}},
                ]
            },
            //Вопрос 2 индекс 3
            {
                dialogText: 'Управляй персонажем с помощью клика или тапа, и направляй его к лестницам, чтобы подниматься выше и собирать свежую еду. Прыгай по этажам, избегая гнилых фруктов, иначе придется начинать заново!',
                buttons: [
                    {text: 'Далее', action: {name: 'closeDialog', arg: {goTo: 'RefrigeratorGameScene', deactivate: true}}},
                ]
            },
        ],
        screensBVI: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Открытая дверца холодильника встречает тебя резким запахом — будто у тебя получилось открыть портал в мир потерянной еды. Контейнеры с непонятным содержимым прочно приросли к нижней полке. Некоторые из них покрыты плесенью, а на других угрожающе поднимается конденсат.',
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Вопрос 2 индекс 1
            {
                dialogText: 'Ты с недоумением разглядываешь наклейки, на которых давно стерлись даты. Через минуту приходит понимание, что некоторые из них “живут” еще со времен предыдущего  новогоднего корпоратива.',
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 2}},
                ]
            },
            //Вопрос 2 индекс 2
            {
                dialogText: 'Просто выбросить в помойку этих бессловесных свидетелей офисной жизни — было бы настоящим преступлением. Ты решаешь всё отсортировать и помочь контейнерам со свежей едой сбежать из этого холодильного царства.',
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 3}},
                ]
            },
            //Финиш индекс 3
            {
                dialogText: 'Это было непросто, но у тебя получилось. Коллеги точно оценят твою заботу!',
                buttons: [
                    {text: 'Далее', action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: true}}},
                ]
            }
        ]
    },

    dialogRefrigeratorFinish: {
        title: "Холодильник",
        background: "kitchen",
        spritesheet: 'dialogKitchenDefault',
        bvi_background: '/assets/images/Kitchen/kitchen.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Это было непросто, но у тебя получилось. Коллеги точно оценят твою заботу!',
                buttons: [
                    {text: 'Далее', action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: false}}},
                    {text: 'Играть ещё раз', action: {name: 'closeDialog', arg: {goTo: 'RefrigeratorGameScene', deactivate: false}}},
                ]
            },
        ]
    },

    dialogTree: {
        title: "Ёлка",
        spritesheet: 'dialogKitchenNight',
        bvi_background: '/assets/images/Kitchen/kitchen_dark.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Ты замечаешь, как часы приближаются к началу официальной части корпоратива. Руководитель уже начинает нервничать: начало вот-вот, но все продолжают усердно работать за компьютерами. Кажется, пришло время проявить инициативу и собрать всех вместе.',
                buttons: [
                    {text: 'Написать в мессенджер', setStatuses: [{status:'director', count: -1},{status:'effectiveness', count: 1}],   action: {name: 'setScreenAction', arg: 1}},
                    {text: 'Оповестить лично', setStatuses: [{status:'effectiveness', count: -1}],  action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Громко крикнуть', setStatuses: [{status:'comrades', count: -1}, {status:'effectiveness', count: 1}], action: {name: 'setScreenAction', arg: 3}},
                    {text: 'Попросить помощи', setStatuses: [{status:'comrades', count: -1}], action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //0твет 1.1 индекс 1
            {
                dialogText: "Ты решаешь отправить в корпоративном мессенджере дружелюбное напоминание. Коллеги начинают лениво заходить на кухню. Прошло целых 15 минут перед тем, как все собрались. Кажется, что в будущем надо быть решительнее.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //0твет 1.2 индекс 2
            {
                dialogText: "Ты решаешь подойти к каждому сотруднику и лично оповестить о начале корпоратива. Многие коллеги были погружены в работу. Некоторые улыбнулись и сказали, что скоро подойдут, другие просто кивнули, не отвлекаясь от экранов. У тебя получилось быстро собрать половину офиса — уже результат!",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //0твет 1.3 индекс 3
            {
                dialogText: "Громко объявив на весь офис о начале официальной части, у тебя получилось привлечь внимание большинства коллег, но реакция была неоднозначной. Некоторые подняли головы и с недовольством посмотрели в твою сторону, а другие, несмотря на скепсис, начали подниматься со своих мест.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //0твет 1.4 индекс 4
            {
                dialogText: "Обратившись за помощью к офис-менеджеру, вы вместе идёте в офис, чтобы сделать объявление. Благодаря поддержке Светы ты чувствуешь себя увереннее — коллеги хорошо реагируют и быстро направляются в сторону кухни.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Вопрос 2 индекс 5
            {
                dialogText: 'Всеми правдами и неправдами получилось собрать весь коллектив на кухне. Становится шумно — коллеги обсуждают последние новости и выходку твоего наставника Алексея на прошлом корпоративе. Руководитель уже стоит у стола и выглядит немного растерянным. Твои действия?',
                buttons: [
                    {text: 'Включить музыку', setStatuses: [{status:'director', count: 1},{status:'effectiveness', count: 1}],   action: {name: 'setScreenAction', arg: 6}},
                    {text: 'Попросить всех успокоиться', setStatuses: [{status:'director', count: 1},{status:'effectiveness', count: 1}],  action: {name: 'setScreenAction', arg: 7}},
                    {text: 'Ничего не делать', setStatuses: [{status:'effectiveness', count: -2}], action: {name: 'setScreenAction', arg: 8}},
                ]
            },
            //0твет 2.1 индекс 6
            {
                dialogText: "Попросив включить музыку, ты видишь, как коллеги перестали разговаривать и повернули головы в сторону динамиков. Звуки праздничной мелодии заполнили кухню — некоторые даже стали пританцовывать. Руководитель улыбнулся и призвал всех к вниманию.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 9}},
                ]
            },
            //0твет 2.2 индекс 7
            {
                dialogText: "Громко и с энтузиазмом ты просишь всех замолчать. В ответ на твой призыв раздались смешки и шёпот, но большинство коллег всё же замерли в ожидании. Руководитель, поймав момент, улыбнулся тебе и начал свою праздничную речь.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 9}},
                ]
            },
            //0твет 2.3 индекс 8
            {
                dialogText: "Разговоры на кухне продолжают звучать. Руководитель заметно нервничает, а другие коллеги погружаются в свои темы, не замечая, что церемония ещё не началась. Инициативу на себя берёт офис-менеджер Светлана. Жаль, это была такая хорошая возможность проявить себя.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 9}},
                ]
            },
            //Вопрос 3 индекс 9
            {
                dialogText: 'Руководитель заканчивает свою речь, а в воздухе повисает молчание. Спустя мгновение раздаются аплодисменты — праздник начинается. Чем займешься?',
                buttons: [
                    {text: 'Пойти поесть', setStatuses: [{status:'comrades', count: 1}, {status:'psychological', count: 1}],   action: {name: 'setScreenAction', arg: 10}},
                    {text: 'Произнести речь', setStatuses: [{status:'effectiveness', count: 1}],  action: {name: 'setScreenAction', arg: 11}},
                    {text: 'Предложить конкурс', setStatuses: [{status:'effectiveness', count: -1}], action: {name: 'setScreenAction', arg: 12}},
                ]
            },
            //0твет 3.1 индекс 10
            {
                dialogText: "Ты подходишь к столу с закусками, на котором тебя встречают тарелки с аппетитными канапе. Рядом смеются и разговаривают коллеги. Вдруг один из них предлагает сыграть в игру: каждый должен рассказать о самом смешном случае, который с ним произошёл на работе. Ты присоединяешься к игре и узнаёшь о коллегах много всего нового.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //0твет 3.2 индекс 11
            {
                dialogText: "Собравшись с духом, ты решаешь произнести поздравительную речь и поблагодарить коллег за поддержку. Ты удачно шутишь, коллеги смеются. Этот неожиданный смелый шаг позволяет тебе стать центром внимания на корпоративе. Так держать!",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //0твет 3.3 индекс 12
            {
                dialogText: "После поздравительной речи руководителя ты присоединяешься к всеобщей эйфории и предлагаешь конкурс на лучший танец. К сожалению, твое предложение встречают недовольные взгляды коллег. Они устали от формальностей, не готовы к активностям, а хотят просто поболтать. Наступает неловкая пауза. Чтобы сгладить ситуацию, вмешивается твой наставник Алексей. Ладно, можно потанцевать просто так.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //Финал индекс 13
            {
                dialogText: 'Отличная работа! Умение брать на себя инициативу и эффективно общаться с людьми — признаки настоящего лидера. Пройди тест, чтобы оценить свои лидерские качества.',
                buttons: [
                    {text: "Продолжить", action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: true}}},
                    {text: "Пройти тест", action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: true, link: 'https://testing.rsv.ru/4cba29dc97984d599ec5cb9245332e4b'}}},
                ]
            },
        ]
    },

    dialogAnna: {
        title: "Анна говорит",
        npcImage: 'anna',
        spritesheet: 'dialogKitchenNight',
        bvi_background: '/assets/images/Kitchen/kitchen_dark.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'В углу кухни, на мягком диване, сидит коллега — Анна. Она не участвует в веселье, а лишь задумчиво смотрит на стену, на которой отражается свет ёлочной гирлянды. На ней простое, но стильное платье, волосы уложены в аккуратную прическу, но взгляд выдает её отстранённость от происходящего вокруг.',
                buttons: [
                    {text: 'Спросить о самочувствии', setStatuses: [{status:'comrades', count: 1},{status:'effectiveness', count: 1}],   action: {name: 'setScreenAction', arg: 1}},
                    {text: 'Рассказать историю', setStatuses: [{status:'comrades', count: 2}],  action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Найти компанию', setStatuses: [{status:'comrades', count: -1}, {status:'effectiveness', count: -1}], action: {name: 'setScreenAction', arg: 3}},
                    {text: 'Игнорировать', setStatuses: [{status:'comrades', count: -2}], action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //0твет 1.1 индекс 1
            {
                dialogText: "Ты осторожно подходишь к Анне и спрашиваешь о её самочувствии. Она слегка вздрагивает и возвращается в реальность. C лёгкой грустью в голосе Анна отвечает, что просто много думает. В её глазах мелькает благодарность за заботу, но в голосе всё ещё слышится тоска.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //0твет 1.2 индекс 2
            {
                dialogText: "Ты решаешь рассказать Анне смешную историю из своей жизни. Сначала она смотрит на тебя с недоумением, но вскоре её губы трогает лёгкая улыбка. Смех становится искренним, и, наконец, она присоединяется к диалогу. Это успех!",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //0твет 1.3 индекс 3
            {
                dialogText: "Ты пытаешься вовлечь Анну в весёлую компанию коллег, но она только сдержанно улыбается и отводит взгляд. На её лице появляется напряжение, и ты замечаешь, как она начинает закрываться. Остальные коллеги быстро теряют интерес, и атмосфера становится неловкой.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //0твет 1.4 индекс 4
            {
                dialogText: "Ты решаешь проигнорировать Анну и присоединяешься к компании коллег. Веселье вокруг нарастает, но ты всё чаще замечаешь, как она сидит в углу одна, её глаза полны тоски. В какой-то момент Анна встает и покидает кухню. Теперь ты чувствуешь вину за своё бездействие.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Вопрос 2 индекс 5
            {
                dialogText: 'Музыка играет, танцы танцуются, а у тебя получается завязать разговор с Анной. Всё идёт хорошо, как вдруг, Алексей проливает на её платье свой коктейль. Твой наставник искренне извиняется, но это не помогает спасти ситуацию. Твои действия?',
                buttons: [
                    {text: 'Помочь с чисткой', setStatuses: [{status:'comrades', count: 1}],   action: {name: 'setScreenAction', arg: 6}},
                    {text: 'Обратить внимание на себя', setStatuses: [{status:'comrades', count: 2}],  action: {name: 'setScreenAction', arg: 7}},
                    {text: 'Пошутить', setStatuses: [{status:'comrades', count: -1}, {status:'director', count: -1}], action: {name: 'setScreenAction', arg: 8}},
                    {text: 'Придумать «коварный» план', setStatuses: [{status:'comrades', count: 1}, {status:'psychological', count: 1}], action: {name: 'setScreenAction', arg: 9}},
                ]
            },
            //0твет 2.1 индекс 6
            {
                dialogText: "Ты предлагаешь Анне отойти в сторону и помочь ей убрать пятно. В процессе вы обсуждаете смешные неловкие моменты. Это помогает ей расслабиться и вернуть хорошее настроение.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 10}},
                ]
            },
            //0твет 2.2 индекс 7
            {
                dialogText: "Ты берёшь со стола ещё один коктейль и проливаешь на себя. Делаешь это максимально громко, чтобы все коллеги обратили внимание. Все смеются, а Анна улыбается и вскоре забывает о пятне на своём платье. Кажется, отвлекающий манёвр сработал!",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 10}},
                ]
            },
            //0твет 2.3 индекс 8
            {
                dialogText: "Ты решаешь пошутить, чтобы разрядить обстановку: «Похоже из Алексея вышел бы классный дизайнер!» Однако шутка не воспринимается так, как ты ожидаешь. Анна смотрит на тебя с недоумением и лёгким раздражением, а Алексей смущённо продолжает оправдываться.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 10}},
                ]
            },
            //0твет 2.4 индекс 9
            {
                dialogText: "Ты предлагаешь Анне объединиться, чтобы вместе придумать шутливый план мести. Вместе вы изобретаете десятки изощренных способов доставки коктейлей на одежду Алексея. Одна идея креативней другой, но в конце мозгового штурма вы решаете ничего не делать. Ведь главное, что вы классно провели время, а платье и постирать можно.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 10}},
                ]
            },
            //Вопрос 3 индекс 10
            {
                dialogText: 'Вечеринка продолжается, и ты замечаешь, что Анна собирается уходить. Она стоит у выхода и смотрит на часы.',
                buttons: [
                    {text: 'Уговорить Анну остаться', setStatuses: [{status:'comrades', count: -2}],   action: {name: 'setScreenAction', arg: 11}},
                    {text: 'Обменяться контактами', setStatuses: [{status:'comrades', count: 1}],  action: {name: 'setScreenAction', arg: 12}},
                    {text: 'Проводить', setStatuses: [{status:'comrades', count: 1}, {status:'psychological', count: 1}], action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //0твет 3.2 индекс 11
            {
                dialogText: "Ты уговариваешь Анну остаться, уверяя, что веселье только начинается. Она в итоге соглашается, но её настроение остаётся подавленным весь вечер. Анна все чаще смотрит на часы, и в итоге уходит расстроенной. Кажется, не надо было уговаривать её остаться.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 14}},
                ]
            },
            //0твет 3.3 индекс 12
            {
                dialogText: "Ты предлагаешь обменяться контактами, чтобы Анна могла всегда написать, если будет грустно. Она соглашается. Кажется, у тебя появился первый друг на работе. Поздравляем!",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 14}},
                ]
            },
            //0твет 3.4 индекс 13
            {
                dialogText: "Ты выходишь с Анной на улицу и, пока она ждёт такси, искренне говоришь ей, как приятно было пообщаться. Добавляешь, что надеешься увидеть ее завтра. Это поднимает ей настроение. Кажется, у тебя появился первый друг на работе. Поздравляем!",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 14}},
                ]
            },
            //Финал индекс 14
            {
                dialogText: 'Способность распознавать и управлять своими эмоциями, а также эмоциями других, чтобы эффективно взаимодействовать с командой, — часть компетенции «эмоциональный интеллект». Пройди тест, чтобы оценить свой уровень эмпатии.',
                buttons: [
                    {text: "Продолжить", action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: true}}},
                    {text: "Пройти тест", action: {name: 'closeDialog', arg: {goTo: 'KitchenScene', deactivate: true, link: 'https://testing.rsv.ru/2034f85fa2dc491a880fe386d9ff0fa3'}}},
                ]
            },
        ]
    },

    dialogProftest: {
        title: "Директор говорит",
        spritesheet: 'dialogProftest',
        background: '/assets/images/Proftest/Proftest_background',
        bvi_background: '/assets/images/Kitchen/kitchen_dark.png',
        npcImage: 'dima',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: "Ты стоишь у стола с закусками, теребя в руках пластиковый стакан с напитком, когда к тебе подходит руководитель компании. Его уверенная улыбка мгновенно разряжает обстановку.",
                buttons: [
                    {text: 'Далее', action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Вопрос 2 индекс 1
            {
                dialogText: "Привет! Ты, наверное, наш новый стажёр? Меня зовут Дмитрий Владимирович. Как тебе наш корпоратив? Уже удалось влиться в компанию? Хочу с тобой поговорить наедине. Будет свободная минутка?",
                buttons: [
                    {text: 'Да, конечно', action: {name: 'setScreenAction', arg: 2}},
                ]
            },
            //Вопрос 3 индекс 2
            {
                dialogText: "Как тебе работа нашего внутреннего отдела, если не секрет? Мне интересно узнать, нравится ли тебе работа с документами или лояльностью сотрудников? Это поможет мне лучше понять.... и направить тебя в отдел, где тебе интереснее всего проходить стажировку",
                buttons: [
                    {text: 'Мне это подходит', action: {name: 'selectProftestAction', arg: {num: 3, orientation: 'hr'}}},
                    {text: 'Не совсем моё, но можно попробовать', action: {name: 'selectProftestAction', arg: {num: 3, orientation: 'hr'}}},
                    {text: "Слишком рутинно для меня", action: {name: 'setScreenAction', arg: 3}},
                    {text: "Неинтересно, хочу другое", action: {name: 'setScreenAction', arg: 3}},
                ]
            },
            //Вопрос 4 индекс 3
            {
                dialogText: "А что думаешь о том, что делают дизайнеры и программисты, которые заняты разработкой нашего главного IT-продукта? Есть ли в этом что-то, что тебя привлекает?",
                buttons: [
                    {text: 'Это моя сфера!', action: {name: 'selectProftestAction', arg: {num: 4, orientation: 'it'}}},
                    {text: 'Интересно, хочу попробовать', action: {name: 'selectProftestAction', arg: {num: 4, orientation: 'it'}}},
                    {text: "Интересно, но слишком сложно", action: {name: 'setScreenAction', arg: 4}},
                    {text: "Сложно, не моё", action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Вопрос 5 индекс 4
            {
                dialogText: "Понятно. А с деньгами какие у тебя отношения? Интересна финансовая аналитика, налогообложение, управленческий учёт?",
                buttons: [
                    {text: 'Это про меня!', action: {name: 'selectProftestAction', arg: {num: 5, orientation: 'finance'}}},
                    {text: 'Можно попробовать', action: {name: 'selectProftestAction', arg: {num: 5, orientation: 'finance'}}},
                    {text: "Интересно, но не люблю рутину", action: {name: 'setScreenAction', arg: 5}},
                    {text: "Точно нет", action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Вопрос 6 индекс 5
            {
                dialogText: "Слушай, ещё у нас есть отдельное направление, связанное с промышленностью. Может туда?",
                buttons: [
                    {text: 'Хочу, хочу!', action: {name: 'selectProftestAction', arg: {num: 6, orientation: 'industry'}}},
                    {text: 'Я подумаю об этом', action: {name: 'selectProftestAction', arg: {num: 6, orientation: 'industry'}}},
                    {text: "У меня аллергия на промышленность", action: {name: 'setScreenAction', arg: 6}},
                    {text: "Мне и здесь хорошо", action: {name: 'setScreenAction', arg: 6}},
                ]
            },
            //Вопрос 7 индекс 6
            {
                dialogText: "Хорошо, с этим разобрались. А как тебе работа наших пиарщиков? Что думаешь про их подход к коммуникациям? Ты видишь себя в этой сфере?",
                buttons: [
                    {text: 'Это точно моё!', action: {name: 'selectProftestAction', arg: {num: 7, orientation: 'pr',finish: 'dialogProftestTotalEmpty'}}},
                    {text: 'Интересно, люблю маркетинг', action: {name: 'selectProftestAction', arg: {num: 7, orientation: 'pr',finish: 'dialogProftestTotalEmpty'}}},
                    {text: "Интересно, но слишком стрессово", action: {name: 'changeDialog', arg: {dialog: 'dialogProftestTotalEmpty', deactivate: false}}},
                    {text: "Неинтересно, слишком сложно", action: {name: 'changeDialog', arg: {dialog: 'dialogProftestTotalEmpty', deactivate: false}}},
                ]
            }
        ]
    },

    dialogProftestTotalEmpty: {
        title: "Директор говорит",
        spritesheet: 'dialogKitchenNight',
        bvi_background: '/assets/images/Kitchen/kitchen_dark.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Спасибо за интересную беседу! Мне было приятно услышать твои мысли и идеи. Хотя я пока не совсем понял, какая работа могла бы подойти тебе лучше всего, я хотел бы предложить пройти наш внутренний курс. Это поможет тебе лучше понять различные направления и выбрать, что тебе ближе. Как ты на это смотришь?',
                buttons: [
                    {text: 'Давай попробуем', action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Вопрос 2 индекс 1
            {
                dialogText: 'Поздравляем! У тебя получилось пройти второй день стажировки в «Компании мечты Плюс». Но это всего лишь игра, проверить свои реальные навыки можно только на реальной стажировке. Если ты ещё не определился со своей будущей профессией, пройди курс «Дело по душе: найти себя в профессии». Это поможет тебе выбрать свой профессиональный вектор.',
                buttons: [
                    {text: 'Пройти курс', action: {name: 'goToCourse'}},
                ]
            }
        ]
    },

    dialogProftestTotalProfi: {
        title: "Директор говорит",
        spritesheet: 'dialogKitchenNight',
        bvi_background: '/assets/images/Kitchen/kitchen_dark.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Спасибо за интересную беседу! Мне было приятно услышать твои мысли и идеи. Учитывая твои предпочтения и подход, я хотел бы предложить тебе подходящую позицию. Думаю, это будет отличная возможность для тебя развиваться и проявить себя. Как ты на это смотришь?',
                buttons: [
                    {text: 'Хочу начать как можно быстрее', action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Вопрос 2 индекс 1
            {
                dialogText: 'Поздравляем! У тебя получилось пройти второй день стажировки в «Компании мечты Плюс». Но это всего лишь игра, проверить свои реальные навыки можно только на реальной стажировке. Поэтому на основе твоих действий в игре мы подобрали для тебя несколько вариантов.',
                buttons: [
                    {text: 'Смотреть стажировки', action: {name: 'goToCourse'}},
                ]
            }
        ]
    },
};

const getters = {
    getDialog: state => dialog =>  {
        return state[dialog];
    },
    getScreen: state => (dialog, index, bvi=false) => {
        console.log(state[dialog]);
        return bvi && dialog.screensBVI ?
            dialog.screensBVI[index] :
            dialog.screens[index];
    },
    getUserOrientationsLink: state => {
        if (state.userOrientations.length === 0) return 'https://rsv.ru/education/course/3/3442/';
        let link = state.originLink;
        state.userOrientations.forEach(orientation=>{
            link += `&industryIds=${orientation}`
        })
        return link;
    }
};

const mutations = {
    setUserOrientations(state, payload) {
        state.dialogProftestTotalEmpty = state.dialogProftestTotalProfi;
        const addOrientations = state.orientations[payload].filter(orientation=>!state.userOrientations.includes(orientation));
        state.userOrientations = [...state.userOrientations, ...addOrientations];
    }
}

export default {
    state,
    getters,
    mutations
};
